import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { CommentList } from '../components/Comment/CommentList'
import { Navigation } from '../components/Navigation/Navigation'
import { TopicList } from '../components/Topic/TopicList'
import { maxWidth } from '../utils/mediaQuery'
import { countUnprocessedItems } from '../utils/topic'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../constants/routes'
import { useModerationState } from '../providers/ModerationStateProvider'
import { useTitle } from '../hooks/title'
import { useLogin } from '../providers/LoginProvider'
import { isCommentator, isModerator, isOperator } from '../utils/user'
import { AreenaTopicModal } from '../components/AreenaTopicModal/AreenaTopicModal'
import { Topic } from '../models/topic'

export const ModerationPage: FC = () => {
  const { topicId } = useParams()
  const { user } = useLogin()
  const {
    topicsAndComments: {
      topics,
      selectedTopic,
      topicFilter,
      setTopicFilter,
      comments,
      notices,
      actions,
      tab,
      setTab,
      setSelectedTopic,
      loadSingleTopic,
      loading
    }
  } = useModerationState()
  const isMobile = useMediaQuery(maxWidth('lg'))
  const navigate = useNavigate()
  const [isAreenaTopicModalOpen, setIsAreenaTopicModalOpen] = useState(false)
  const [editedAreenaTopic, setEditedAreenaTopic] = useState<Topic>()

  const unprocessedItems = countUnprocessedItems(topics)
  useTitle(`${unprocessedItems > 0 ? `(${unprocessedItems}) ` : ''}Yle Comments Admin`)

  const onSelectTopic = useCallback(
    (topicId?: string) => {
      if (isMobile) {
        navigate(`${routes.moderation}/${topicId}`)
      } else {
        setSelectedTopic(topicId)
      }
    },
    [isMobile, setSelectedTopic, navigate]
  )

  useEffect(() => {
    if (topicId) {
      loadSingleTopic(topicId)
    }
  }, [topicId, loadSingleTopic])

  return (
    <Navigation currentRoute="moderation">
      {!isOperator(user) && !isModerator(user) && !isCommentator(user) ? (
        <Typography sx={{ maxWidth: 600 }}>
          You don&apos;t have access rights to the new Comments Admin. Please contact Cecilia Wikman
          or{' '}
          <a href="https://chat.google.com/room/AAAAI38keo0?cls=7" target="_blank" rel="noreferrer">
            Yle Comments support channel
          </a>
          .
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'stretch',
            flex: 1
          }}
        >
          <TopicList
            topics={topics}
            topicFilter={topicFilter}
            setTopicFilter={setTopicFilter}
            selectedTopic={selectedTopic}
            setSelectedTopic={onSelectTopic}
            loadSingleTopic={loadSingleTopic}
            openAreenaTopicModal={
              isOperator(user) ? () => setIsAreenaTopicModalOpen(true) : undefined
            }
          />
          {!isMobile &&
            (selectedTopic ? (
              <CommentList
                topic={topics.find(t => t.id === selectedTopic)}
                comments={comments}
                notices={notices}
                actions={actions}
                tab={tab}
                setTab={setTab}
                loading={loading}
                editTopic={
                  isOperator(user)
                    ? (topic: Topic) => {
                        setEditedAreenaTopic(topic)
                        setIsAreenaTopicModalOpen(true)
                      }
                    : undefined
                }
              />
            ) : (
              <Box sx={{ flex: 1.6 }} />
            ))}
          <AreenaTopicModal
            open={isAreenaTopicModalOpen}
            topic={editedAreenaTopic}
            handleClose={() => {
              setEditedAreenaTopic(undefined)
              setIsAreenaTopicModalOpen(false)
            }}
            navigate={navigate}
          />
        </Box>
      )}
    </Navigation>
  )
}
