import React, { FC } from 'react'
import { Button } from '@mui/material'
import { useKeyboardShortcut } from '../../hooks/keyboardShortcut'

export type AcceptButtonProps = {
  onAccept: () => void
  useKeyboardShortcuts?: boolean
  variant?: 'outlined' | 'contained'
}

export const AcceptButton: FC<AcceptButtonProps> = ({
  useKeyboardShortcuts,
  onAccept,
  variant
}) => {
  useKeyboardShortcut(useKeyboardShortcuts || false, 'f', onAccept)

  return (
    <Button onClick={onAccept} sx={{ marginLeft: 1 }} variant={variant} size="small">
      {useKeyboardShortcuts ? 'Accept (F)' : 'Accept'}
    </Button>
  )
}
