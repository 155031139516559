/* istanbul ignore file */

import { parseISO } from 'date-fns'
import { User, Block, UserBlock, AdminUserRoles, AdminUser, UserCommentStats } from '../models/user'
import { Comment } from '../models/comment'
import { Notice } from '../models/notice'
import type { Topic, EditableTopic } from '../models/topic'
import { ApiOptions } from '../providers/ApiOptionsProvider'
import { BackendUser } from '../providers/LoginProvider'
import { getJson, fetchJson, fetchJsonWithBody } from './fetch-methods'
import { Tag } from '../models/tag'
import NotFoundError from '../errors/NotFoundError'
import { difference } from '../utils/util'
import { DsaCategory } from '../models/dsaCategory'

export const checkToken = async (apiOptions: ApiOptions, token: string): Promise<BackendUser> => {
  const { isOperator, isModerator, isCommentator } = await getJson<BackendUser>(
    apiOptions,
    token,
    'v1/login'
  )
  return { isOperator, isModerator, isCommentator }
}

export type TopicIdParams = {
  topicId: string
}

export type GetTopicsParams = {
  hidden: boolean
  locked: boolean
}

type RawBlock = {
  blockedUserId: number
  reason: string
  blocking_user_id: number
  createdAt: string
}

type RawUserBlock = {
  user: User
  block: RawBlock
}

type PutCloseNoticeParams = {
  topicId: string
  commentId: string
  noticeId: string
}

type PutRejectCommentParams = {
  topicId: string
  commentId: string
  reason: string
}

type PutCommentParams = {
  topicId: string
  commentId: string
}

type RawTopic = Omit<Topic, 'oldestOpenModerationTaskCreatedAt' | 'openedAt' | 'lockedAt'> & {
  oldestOpenModerationTaskCreatedAt: string | null
  openedAt: string | null
  lockedAt: string | null
}
type RawComment = Omit<Comment, 'createdAt' | 'acceptedAt' | 'rejectedAt'> & {
  createdAt: string
  acceptedAt: string | null
  rejectedAt: string | null
}
type RawNotice = Omit<Notice, 'createdAt'> & { createdAt: string }

const parseDates =
  <I extends Record<string, unknown>, O extends Record<string, unknown>>(dateKeys: (keyof I)[]) =>
  (obj: I): O => {
    const dates = dateKeys.reduce(
      (a, b) => ({ ...a, [b]: obj[b] ? parseISO(obj[b] as string) : null }),
      {}
    )

    return {
      ...obj,
      ...dates
    } as O
  }

const fields = [
  'id',
  'externalId',
  'url',
  'title',
  'unprocessedNoticesCount',
  'unmoderatedCommentsCount',
  'oldestOpenModerationTaskCreatedAt',
  'isAutomoderated',
  'isLocked',
  'isHidden',
  'tags',
  'openedAt',
  'lockedAt'
]

export const getTopic = async (
  { topicId }: TopicIdParams,
  apiOptions: ApiOptions,
  token: string
): Promise<Topic | undefined> => {
  try {
    const rawTopic = await getJson<RawTopic>(
      apiOptions,
      token,
      `v1/topics/${topicId}`,
      `&fields=${fields.join(',')}`
    )
    return parseDates<RawTopic, Topic>([
      'oldestOpenModerationTaskCreatedAt',
      'openedAt',
      'lockedAt'
    ])(rawTopic)
  } catch (e) {
    if (e instanceof NotFoundError) {
      return undefined
    }
    throw e
  }
}

export const getTopics = async (
  { hidden, locked }: GetTopicsParams,
  apiOptions: ApiOptions,
  token: string
): Promise<Topic[]> => {
  const statusParam =
    hidden || locked ? `is_hidden=${hidden}&is_locked=${locked}` : 'is_open_moderation=true'
  const params = `&${statusParam}&fields=${fields}&limit=300`
  return (await getJson<RawTopic[]>(apiOptions, token, 'v1/topics', params)).map(
    parseDates<RawTopic, Topic>(['oldestOpenModerationTaskCreatedAt', 'openedAt', 'lockedAt'])
  )
}

export const getComments = async (
  { topicId }: TopicIdParams,
  apiOptions: ApiOptions,
  token: string
): Promise<Comment[]> => {
  const params = ''
  const endpoint = `v1/topics/${topicId}/comments`
  return (await getJson<RawComment[]>(apiOptions, token, endpoint, params)).map(
    parseDates<RawComment, Comment>(['createdAt', 'acceptedAt', 'rejectedAt'])
  )
}

export const getNotices = async (
  { topicId }: TopicIdParams,
  apiOptions: ApiOptions,
  token: string
): Promise<Notice[]> => {
  const params = '&status=open'
  const endpoint = `v1/topics/${topicId}/notices`
  return (await getJson<RawNotice[]>(apiOptions, token, endpoint, params)).map(
    parseDates<RawNotice, Notice>(['createdAt'])
  )
}

export const closeNotice = async (
  { topicId, commentId, noticeId }: PutCloseNoticeParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const endpoint = `v1/topics/${topicId}/comments/${commentId}/notices/${noticeId}/close`
  return await fetchJsonWithBody(apiOptions, token, endpoint, undefined, 'PUT')
}

export const rejectComment = async (
  { topicId, commentId, reason }: PutRejectCommentParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const endpoint = `v1/topics/${topicId}/comments/${commentId}/reject`
  const body = { reason }
  return parseDates<RawComment, Comment>(['createdAt', 'acceptedAt', 'rejectedAt'])(
    await fetchJsonWithBody<RawComment>(apiOptions, token, endpoint, body, 'PUT')
  )
}

type PutChangeRejectReasonParams = {
  topicId: string
  commentId: string
  reason: string
}

export const changeRejectReason = async (
  { topicId, commentId, reason }: PutChangeRejectReasonParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const endpoint = `v1/topics/${topicId}/comments/${commentId}/reject-reason`
  const body = { reason }
  return parseDates<RawComment, Comment>(['createdAt', 'acceptedAt', 'rejectedAt'])(
    await fetchJsonWithBody<RawComment>(apiOptions, token, endpoint, body, 'PUT')
  )
}

export const acceptComment = async (
  { topicId, commentId }: PutCommentParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const endpoint = `v1/topics/${topicId}/comments/${commentId}/accept`
  return parseDates<RawComment, Comment>(['createdAt', 'acceptedAt', 'rejectedAt'])(
    await fetchJsonWithBody<RawComment>(apiOptions, token, endpoint, undefined, 'PUT')
  )
}

export const unmoderateComment = async (
  { topicId, commentId }: PutCommentParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const endpoint = `v1/topics/${topicId}/comments/${commentId}/unmoderate`
  return parseDates<RawComment, Comment>(['createdAt', 'acceptedAt', 'rejectedAt'])(
    await fetchJsonWithBody<RawComment>(apiOptions, token, endpoint, undefined, 'PUT')
  )
}

export type PostReplyParams = {
  topicId: string
  parentId?: string
  content: string
}

export const postComment = async (
  { topicId, parentId, content }: PostReplyParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const endpoint = `v1/topics/${topicId}/comments`
  const reply = { parentId, content, anonymousComment: false }
  return parseDates<RawComment, Comment>(['createdAt', 'acceptedAt', 'rejectedAt'])(
    await fetchJsonWithBody<RawComment>(apiOptions, token, endpoint, reply)
  )
}

export type SearchUsersParams = {
  nickname: string
}

export const searchUsers = async (
  { nickname }: SearchUsersParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const endpoint = 'v1/search/users'
  const queryParameters = `nickname=${nickname}`
  return await getJson<User[]>(apiOptions, token, endpoint, queryParameters)
}

type GetUserCommentsParams = {
  userId: string
}

export const getUserCommentStats = async (
  { userId }: GetUserCommentsParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const endpoint = `v1/users/internal/${userId}/stats`
  return getJson<UserCommentStats>(apiOptions, token, endpoint)
}

const parseUserBlock = (rawUserBlock: RawUserBlock): UserBlock => {
  return {
    ...rawUserBlock,
    block: parseDates<RawBlock, Block>(['createdAt'])(rawUserBlock.block)
  }
}

type CreateBlockParams = {
  userId: string
  reasonDescription: string
  dsa: DsaCategory
}

type CreateUserBlock = (
  createBlock: CreateBlockParams,
  apiOptions: ApiOptions,
  token: string
) => Promise<UserBlock>

export const createBlock: CreateUserBlock = async (
  { userId, reasonDescription, dsa },
  apiOptions,
  token
) => {
  const endpoint = 'v1/block'
  const body = { blockedUserId: userId, reasonDescription, dsa }
  const rawUserBlock = await fetchJsonWithBody<RawUserBlock>(apiOptions, token, endpoint, body)
  return parseUserBlock(rawUserBlock)
}

type DeleteBlockParams = { userId: string }

type UserNoBlock = Omit<UserBlock, 'block'> & { block: null }

export const deleteBlock = async (
  { userId }: DeleteBlockParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const endpoint = `v1/users/internal/${userId}/block`
  return await fetchJsonWithBody<UserNoBlock>(apiOptions, token, endpoint, undefined, 'DELETE')
}

type GetUserParams = {
  userId: string
}

export const getUser = async ({ userId }: GetUserParams, apiOptions: ApiOptions, token: string) => {
  const endpoint = `v1/users/internal/${userId}`
  return await getJson<User>(apiOptions, token, endpoint)
}

type GetBlockParams = {
  userId: string
}

export const getUserBlock = async (
  { userId }: GetBlockParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const endpoint = `v1/users/internal/${userId}/block`
  const body = await getJson<RawUserBlock | UserNoBlock>(apiOptions, token, endpoint)
  if (body.block) {
    return parseUserBlock(body)
  } else return body
}

export const getUserBlocks = async (apiOptions: ApiOptions, token: string) => {
  const endpoint = `v1/block`
  const body = await getJson<RawUserBlock[]>(apiOptions, token, endpoint)
  return body.map(parseUserBlock)
}

export const getTags = (apiOptions: ApiOptions, token: string) =>
  getJson<Tag[]>(apiOptions, token, 'v1/tags')

export const getAdminUsers = (apiOptions: ApiOptions, token: string) =>
  getJson<AdminUser[]>(apiOptions, token, 'v1/users/google')

type GetAdminUserParams = {
  email: string
}

export const getAdminUser = (
  { email }: GetAdminUserParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const endpoint = `v1/users/google/${email}`
  return getJson<AdminUser>(apiOptions, token, endpoint)
}

type SaveAdminUserParams = {
  email: string
  roles: AdminUserRoles
  tags: string[]
  isActive?: boolean
}

export const saveAdminUser = (
  { email, roles, tags, isActive = true }: SaveAdminUserParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const endpoint = `v1/users/google/${email}/authorization`
  const body = { roles, tags, isActive }
  return fetchJsonWithBody<AdminUser>(apiOptions, token, endpoint, body)
}

export type SearchAdminUserParams = {
  email: string
}

export const searchAdminUsers = (
  { email }: SearchAdminUserParams,
  apiOptions: ApiOptions,
  token: string
): Promise<string[]> => {
  const endpoint = 'v1/users/search'
  return getJson(apiOptions, token, endpoint, `email=${email}`)
}

export type UpdateTagsParams = {
  topicId: string
  oldTagIds: string[]
  newTagIds: string[]
}

export const updateTags = async (
  { topicId, oldTagIds, newTagIds }: UpdateTagsParams,
  apiOptions: ApiOptions,
  token: string
) => {
  const oldSet = new Set(oldTagIds)
  const newSet = new Set(newTagIds)
  const toDelete = difference(oldSet, newSet)
  const toAdd = difference(newSet, oldSet)
  for (const tagId of toDelete) {
    await fetchJson(apiOptions, token, `v1/topics/${topicId}/tags/${tagId}`, { method: 'DELETE' })
  }
  for (const tagId of toAdd) {
    await fetchJson(apiOptions, token, `v1/topics/${topicId}/tags/${tagId}`, { method: 'POST' })
  }
}

export type SetIsAutomoderatedParams = {
  id: string
  isAutomoderated: boolean
}

export const setIsAutomoderated = (
  { id, isAutomoderated }: SetIsAutomoderatedParams,
  apiOptions: ApiOptions,
  token: string
): Promise<{ isAutomoderated: boolean }> =>
  fetchJsonWithBody(
    apiOptions,
    token,
    `v1/topics/${id}/set-is-automoderated`,
    { isAutomoderated },
    'PUT'
  )

export type UpdateTopicParams = Partial<EditableTopic> & {
  id: string
}

export const updateTopic = (
  { id, ...topic }: UpdateTopicParams,
  apiOptions: ApiOptions,
  token: string
): Promise<Topic> =>
  fetchJsonWithBody<RawTopic>(apiOptions, token, `v1/topics/${id}`, topic, 'PATCH').then(
    parseDates<RawTopic, Topic>(['oldestOpenModerationTaskCreatedAt', 'openedAt', 'lockedAt'])
  )

export type SaveTopicParams = EditableTopic & {
  tagIds: string[]
}

export const saveTopic = (
  topic: SaveTopicParams,
  apiOptions: ApiOptions,
  token: string
): Promise<Topic> =>
  fetchJsonWithBody(apiOptions, token, 'v1/topics', { ...topic, allowAnonymousCommenting: false })
