import React, { FC } from 'react'
import { Comment } from '../../models/comment'
import { formatTimestamp } from '../../utils/date-utils'
import { isToday } from 'date-fns'
import { Tooltip, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { isAccepted } from '../../utils/comment'

type ModerationInfoProps = {
  comment: Comment
}

const getModeratedAt = (comment: Comment) => {
  if (comment.acceptedAt) {
    return formatTimestamp(comment.acceptedAt)
  } else if (comment.rejectedAt) {
    return formatTimestamp(comment.rejectedAt)
  }
  return null
}

export const ModerationInfo: FC<ModerationInfoProps> = ({ comment }) => {
  const moderatedAt = getModeratedAt(comment)
  const title = moderatedAt && (
    <>
      {`${isAccepted(comment) ? 'Accepted' : 'Rejected'} by ${comment.moderatedBy}`} <br />
      {`at ${moderatedAt}`}
    </>
  )
  return (
    <Tooltip title={title ? <Typography sx={{ fontSize: '14px' }}>{title}</Typography> : ''}>
      <Typography sx={{ fontSize: '14px', mr: '4px', cursor: title !== null ? 'help' : 'inherit' }}>
        {formatTimestamp(
          comment.createdAt,
          isToday(comment.createdAt)
            ? {
                weekday: undefined,
                day: undefined,
                year: undefined,
                month: undefined,
                second: '2-digit'
              }
            : {}
        )}
        {title !== null && (
          <InfoOutlined sx={{ width: '16px', verticalAlign: 'bottom', ml: '4px' }} />
        )}
      </Typography>
    </Tooltip>
  )
}
