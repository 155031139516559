import React, { FC, ReactElement } from 'react'
import { Announcement, CheckCircle, Message, RemoveCircle, Warning } from '@mui/icons-material'
import {
  Box,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery
} from '@mui/material'
import { maxWidth } from '../../utils/mediaQuery'

export const FILTER_TABS = ['ALL', 'FLAGGED', 'UNMODERATED', 'ACCEPTED', 'REJECTED'] as const

export type FilterTab = (typeof FILTER_TABS)[number]

type CommentFilterTabsProps = {
  onChange: (tab: FilterTab) => void
  value: FilterTab
  counts: Record<FilterTab, number>
  filtered: number | null
}

const iconProps = { mr: '6px', fontSize: '18px' }

export const COMMENT_FILTER_ICON: Record<string, ReactElement | undefined> = {
  ALL: <Message sx={{ color: '#6750A4', ...iconProps }} />,
  ACCEPTED: <CheckCircle sx={{ color: 'success.main', ...iconProps }} />,
  UNMODERATED: <Announcement sx={{ color: '#6750A4', ...iconProps }} />,
  UNMODERATED_SELECTED: <Announcement sx={{ color: 'white', ...iconProps }} />,
  FLAGGED: <Warning sx={{ color: 'warning.main', ...iconProps }} />,
  REJECTED: <RemoveCircle sx={{ color: 'error.main', ...iconProps }} />
}

export const commentFilterTabSx = {
  fontSize: '14px',
  fontWeight: '500',
  minHeight: '40px',
  height: '40px',
  flex: 1,
  lineHeight: 1.2,
  '&.Mui-selected': {
    bgcolor: 'primary.dark',
    color: 'primary.contrastText',
    '&:hover': {
      bgcolor: 'primary.main'
    }
  }
}

export const CommentFilterTabs: FC<CommentFilterTabsProps> = ({
  onChange,
  value,
  counts,
  filtered
}) => {
  const isMobile = useMediaQuery(maxWidth('lg'))

  const getCountText = (tab: FilterTab) => {
    return filtered !== null && value === tab ? `${filtered} of ${counts[tab]}` : counts[tab]
  }

  return (
    <>
      {isMobile && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: 2
          }}
        >
          <Select value={value} onChange={e => onChange(e.target.value as FilterTab)}>
            <MenuItem value="ALL">{`All (${getCountText('ALL')})`}</MenuItem>
            <MenuItem value="FLAGGED">{`Flagged (${getCountText('FLAGGED')})`}</MenuItem>
            <MenuItem value="UNMODERATED">{`Unmoderated (${getCountText(
              'UNMODERATED'
            )})`}</MenuItem>
            <MenuItem value="ACCEPTED">{`Accepted (${getCountText('ACCEPTED')})`}</MenuItem>
            <MenuItem value="REJECTED">{`Rejected (${getCountText('REJECTED')})`}</MenuItem>
          </Select>
        </Box>
      )}
      {!isMobile && (
        // Tabs component will warn about 'display: none' css parameter,
        // therefore we use media query instead of responsive sx-prop values
        <ToggleButtonGroup
          exclusive
          value={value}
          onChange={(_, tab) => {
            if (tab !== null) {
              onChange(tab as FilterTab)
            }
          }}
          sx={{ marginBottom: '16px', minHeight: '40px', width: '100%' }}
        >
          <ToggleButton value="ALL" sx={{ ...commentFilterTabSx, flex: 0.7 }}>
            {COMMENT_FILTER_ICON.ALL} {`All (${getCountText('ALL')})`}
          </ToggleButton>
          <ToggleButton value="FLAGGED" sx={{ ...commentFilterTabSx, flex: 0.8 }}>
            {COMMENT_FILTER_ICON.FLAGGED} {`Flagged (${getCountText('FLAGGED')})`}
          </ToggleButton>
          <ToggleButton value="UNMODERATED" sx={{ ...commentFilterTabSx, flex: 1.1 }}>
            {value === 'UNMODERATED'
              ? COMMENT_FILTER_ICON.UNMODERATED_SELECTED
              : COMMENT_FILTER_ICON.UNMODERATED}{' '}
            {`Unmoderated (${getCountText('UNMODERATED')})`}
          </ToggleButton>
          <ToggleButton value="ACCEPTED" sx={commentFilterTabSx}>
            {COMMENT_FILTER_ICON.ACCEPTED} {`Accepted (${getCountText('ACCEPTED')})`}
          </ToggleButton>
          <ToggleButton value="REJECTED" sx={commentFilterTabSx}>
            {COMMENT_FILTER_ICON.REJECTED} {`Rejected (${getCountText('REJECTED')})`}
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </>
  )
}
