import { useEffect } from 'react'

type UseKeyboardShortcut = (
  isEnabled: boolean,
  key: string,
  callback: () => void,
  modifiers?: { altKey: boolean; ctrlKey: boolean; shiftKey: boolean }
) => void

const IGNORE_TARGETS = ['INPUT', 'TEXTAREA', 'LI']

export const useKeyboardShortcut: UseKeyboardShortcut = (isEnabled, key, callback, modifiers) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.altKey === (modifiers?.altKey || false) &&
        e.ctrlKey === (modifiers?.ctrlKey || false) &&
        e.shiftKey === (modifiers?.shiftKey || false) &&
        e.key.toLowerCase() === key &&
        e.target instanceof Element &&
        !IGNORE_TARGETS.includes(e.target.nodeName)
      ) {
        callback()
        e.stopPropagation()
      }
    }

    if (isEnabled) {
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isEnabled, key, callback, modifiers])
}
