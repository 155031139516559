import React, { FC, ReactNode } from 'react'
import { createContext } from '../utils/context'
import {
  TopicsAndComments,
  useTopicsAndComments
} from '../hooks/topicsAndComments/topicsAndComments'

export type ModerationState = {
  topicsAndComments: TopicsAndComments
}

const [useModerationState, Provider] = createContext<ModerationState>('ModerationStateContext')

export { useModerationState }

export const ModerationStateProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const topicsAndComments = useTopicsAndComments()

  return <Provider value={{ topicsAndComments }}>{children}</Provider>
}
