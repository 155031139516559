import React, { FC, ReactNode } from 'react'
import { Box, Button, Chip, ListItem, Typography } from '@mui/material'
import { Comment } from '../../models/comment'
import { formatDistanceToNow } from 'date-fns'
import { isModerator } from '../../utils/user'
import { Notice } from '../../models/notice'
import { Quote } from './Quote'
import { BlockModal } from '../BlockModal/BlockModal'
import { useLogin } from '../../providers/LoginProvider'
import { ModerationInfo } from './ModerationInfo'
import { AiModeration } from '../AiModeration/AiModeration'
import { RejectMenu } from './RejectMenu'
import { RejectReason } from '../../models/rejectReason'
import { FlaggingBanner } from './FlaggingBanner'

export type RenderComment = Comment & {
  quote: Comment | null
  notices: Notice[] | null
}

type CommentListItemProps = {
  comment: RenderComment
  commentIcon: ReactNode | null
  onFilter: (searchTerm: string) => void
  onChangeRejectReason: (comment: Comment, reason: RejectReason) => void
  actions: ReactNode
  tree: boolean
}

const automaticModerator = 'Automatic Moderator'

export const CommentListItem: FC<CommentListItemProps> = ({
  comment,
  commentIcon,
  onFilter,
  actions,
  tree,
  onChangeRejectReason
}) => {
  const [showBlockUser, setShowBlockUser] = React.useState(false)
  const { user } = useLogin()
  const anonymousComment = comment.anonymousComment
  const isChild = tree && comment.parentId != null

  return (
    <ListItem
      divider
      sx={{
        padding: '16px 24px 16px 24px',
        borderLeft: isChild ? '16px solid #edf0f3' : '0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: 'auto'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '2px',
          alignItems: 'center'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            {commentIcon && (
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {commentIcon}
              </Box>
            )}
            <Typography sx={{ fontSize: '14px' }}>{`${formatDistanceToNow(
              comment.notices && comment.notices.length > 0
                ? comment.notices[0].createdAt
                : comment.acceptedAt || comment.rejectedAt || comment.createdAt
            )} ago`}</Typography>
          </Box>
          {comment.rejectReason && isModerator(user) && (
            <RejectMenu
              onChange={reason => onChangeRejectReason(comment, reason)}
              rejectReason={comment.rejectReason}
            />
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          {actions}
        </Box>
      </Box>
      {comment.notices && comment.notices.length > 0 && (
        <FlaggingBanner notices={comment.notices} />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Typography sx={{ fontSize: '16px', fontWeight: 500, marginRight: 1 }}>
          {comment.author}
        </Typography>
        <ModerationInfo comment={comment} />
        {!anonymousComment &&
          isModerator(user) &&
          (comment.createdByBannedUser ? (
            <Typography variant="body2">This user is banned</Typography>
          ) : (
            <Button
              onClick={() => {
                setShowBlockUser(true)
              }}
              sx={{
                fontSize: '14px',
                color: '#000',
                textDecoration: 'underline',
                fontWeight: '400',
                textTransform: 'none',
                lineHeight: '1em'
              }}
              size="small"
              variant="text"
            >
              Ban user
            </Button>
          ))}
      </Box>
      {comment.quote && <Quote author={comment.quote.author} content={comment.quote.content} />}
      <Typography data-testid="comment-list-item-content" sx={{ whiteSpace: 'pre-wrap' }}>
        {comment.content}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 1,
          gap: 2,
          alignItems: 'center'
        }}
      >
        <AiModeration comment={comment} hideRejectReason={!comment.aiModerationLabel} />
        {comment.moderatedBy === automaticModerator && (
          <Chip
            onClick={() => onFilter(automaticModerator)}
            label={comment.acceptedAt === null ? 'Auto-rejected' : 'Auto-accepted'}
            variant="outlined"
          />
        )}
      </Box>
      <BlockModal
        key={comment.authenticatedAuthor}
        authorId={comment.authenticatedAuthor}
        author={comment.author}
        open={showBlockUser}
        handleClose={() => setShowBlockUser(false)}
        onSuccess={() => setShowBlockUser(false)}
      />
    </ListItem>
  )
}
