import React, { useRef, useState } from 'react'
import { REJECT_REASONS } from '../../utils/util'
import { Box, ButtonBase, Menu, MenuItem } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { RejectReason } from '../../models/rejectReason'
import { useSettings } from '../../providers/SettingsProvider'
import CheckIcon from '@mui/icons-material/Check'
import { palette } from '../../styles/colors'

type RejectMenuProps = {
  rejectReason: RejectReason
  onChange: (reason: RejectReason) => void
}

export const RejectMenu = ({ rejectReason, onChange }: RejectMenuProps) => {
  const buttonRef = useRef(null)
  const [open, setOpen] = useState(false)
  const { language } = useSettings()
  const onOpen = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  return (
    <Box>
      <ButtonBase
        ref={buttonRef}
        sx={{
          fontSize: '14px',
          color: '#000',
          fontWeight: '400',
          textTransform: 'none',
          lineHeight: '1em',
          textAlign: 'left',
          padding: 0,
          marginBottom: '2px'
        }}
        onClick={onOpen}
        focusRipple
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
      >
        {`Reason: ${REJECT_REASONS(language)[rejectReason]}`}
        <KeyboardArrowDown fontSize="small" />
      </ButtonBase>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorEl={buttonRef.current}
        open={open}
        onClose={onClose}
      >
        {Object.entries(REJECT_REASONS(language)).map(([reason, translation]) => (
          <MenuItem
            dense
            disabled={(reason as RejectReason) === 'TOP_COMMENT_REJECTED'}
            key={reason}
            onClick={() => {
              onClose()
              onChange(reason as RejectReason)
            }}
          >
            <CheckIcon
              sx={{
                color: palette.grey.dark,
                height: '16px',
                width: '16px',
                marginRight: '16px',
                visibility: reason === rejectReason ? 'visible' : 'hidden'
              }}
            />
            {translation}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
