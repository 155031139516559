import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { useSnackbar } from 'notistack'
import * as api from '../../api/comments-api'
import { FilterTab } from '../../components/Comment/CommentFilterTabs'
import { TopicFilter } from '../../components/Topic/TopicStateFilter'
import { useLogin } from '../../providers/LoginProvider'
import { Comment } from '../../models/comment'
import { Notice } from '../../models/notice'
import { Topic } from '../../models/topic'
import { showSnackbar, wrapError } from '../../utils/snackbar'
import { isUpcomingTopic } from '../../utils/topic'
import { isModerator } from '../../utils/user'
import { useEndpoint } from '../endpoint'
import { useInterval } from '../interval'
import {
  closeNoticesAction,
  moderateCommentAction,
  newCommentAction,
  selectTopicAction,
  setCommentsAction,
  setIsAutomoderatedAction,
  setSingleTopicAction,
  setTabAction,
  setTopicFilterAction,
  setTopicsAction,
  updateCommentAction
} from './actions'
import { TopicsAndCommentsState, reducer } from './reducer'
import { RejectReason } from '../../models/rejectReason'

export type CommentActions = {
  onCloseNotices: (notices: Notice[]) => Promise<void>
  onRejectComment: (comment: Comment, reason: RejectReason) => Promise<void>
  onAcceptComment: (comment: Comment) => Promise<void>
  onReplyComment: (params: api.PostReplyParams) => Promise<void>
  onUnmoderateComment: (comment: Comment) => void
  onSetIsAutomoderated: (params: api.SetIsAutomoderatedParams) => Promise<void>
  onChangeRejectReason: (comment: Comment, reason: RejectReason) => void
}

export type TopicsAndComments = TopicsAndCommentsState & {
  setSelectedTopic: (id: string | undefined) => void
  loadSingleTopic: (id: string) => void
  setTopicFilter: (filter: TopicFilter) => void
  setTab: (tab: FilterTab) => void
  actions: CommentActions
}

export const useTopicsAndComments = (): TopicsAndComments => {
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useLogin()
  const isMod = isModerator(user)
  const [state, dispatch] = useReducer(reducer, {
    topics: [],
    selectedTopic: undefined,
    singleTopic: undefined,
    topicFilter: 'Open',
    comments: [],
    notices: [],
    tab: isMod ? 'UNMODERATED' : 'ACCEPTED',
    loading: false
  })
  const getTopic = useEndpoint(api.getTopic)
  const getTopics = useEndpoint(api.getTopics)
  const setIsAutomoderated = useEndpoint(api.setIsAutomoderated)
  const getComments = useEndpoint(api.getComments)
  const getNotices = useEndpoint(api.getNotices)
  const closeNotice = useEndpoint(api.closeNotice)
  const rejectComment = useEndpoint(api.rejectComment)
  const changeRejectReason = useEndpoint(api.changeRejectReason)
  const acceptComment = useEndpoint(api.acceptComment)
  const postComment = useEndpoint(api.postComment)
  const unmoderateComment = useEndpoint(api.unmoderateComment)

  const includeHidden = state.topicFilter === 'Hidden'
  const includeLocked = state.topicFilter === 'Closed' || state.topicFilter === 'Upcoming'

  const handleError = useCallback(
    (e: unknown) => {
      console.error('Error', e)
      showSnackbar(enqueueSnackbar, 'error', wrapError(e))
    },
    [enqueueSnackbar]
  )

  const setTopics = useCallback((topics: Topic[]) => dispatch(setTopicsAction(topics)), [])

  const setSelectedTopic = useCallback(
    (id: string | undefined) => dispatch(selectTopicAction(id)),
    []
  )

  const loadSingleTopic = useCallback(
    (topicId: string) =>
      getTopic({ topicId })
        .then(topic => {
          if (topic !== undefined) {
            dispatch(setSingleTopicAction(topic))
          } else {
            handleError(new Error(`Topic with id ${topicId} was not found`))
          }
        })
        .catch(handleError),
    [getTopic, handleError]
  )

  const setTopicFilter = useCallback(
    (filter: TopicFilter) => dispatch(setTopicFilterAction(filter)),
    []
  )

  const setTab = useCallback((tab: FilterTab) => dispatch(setTabAction(tab)), [])

  const getAndSetTopics = useCallback(
    () =>
      getTopics({ hidden: includeHidden, locked: includeLocked })
        .then(setTopics)
        .catch(handleError),
    [getTopics, setTopics, handleError, includeHidden, includeLocked]
  )

  useInterval(getAndSetTopics, 10000)

  useEffect(() => {
    setSelectedTopic(undefined)
    setTopics([])
    getAndSetTopics()
  }, [getAndSetTopics, setSelectedTopic, setTopics])

  const loadComments = useCallback(
    async (selectTab?: boolean) => {
      try {
        const topicId = state.selectedTopic
        if (topicId) {
          const comments = await getComments({ topicId })
          const notices = isMod ? await getNotices({ topicId }) : []
          const newTab = isMod ? (notices.length > 0 ? 'FLAGGED' : 'UNMODERATED') : 'ACCEPTED'
          dispatch(setCommentsAction(comments, notices, selectTab ? newTab : undefined))
        }
      } catch (e) {
        handleError(e)
      }
    },
    [state.selectedTopic, isMod, getComments, getNotices, handleError]
  )

  useInterval(loadComments, 10000)

  useEffect(() => {
    loadComments(true)
  }, [loadComments])

  const onSetIsAutomoderated = useCallback(
    (params: api.SetIsAutomoderatedParams) =>
      setIsAutomoderated(params)
        .then(({ isAutomoderated }) =>
          dispatch(setIsAutomoderatedAction(params.id, isAutomoderated))
        )
        .catch(handleError),
    [setIsAutomoderated, handleError]
  )

  const onChangeRejectReason = useCallback(
    (comment: Comment, reason: RejectReason) => {
      changeRejectReason({ topicId: comment.topicId, commentId: comment.id, reason })
        .then(comment => dispatch(updateCommentAction(comment)))
        .catch(handleError)
    },
    [changeRejectReason, handleError]
  )

  const onCloseNotices = useCallback(
    (notices: Notice[]) =>
      Promise.all(
        notices.map(notice =>
          closeNotice({
            topicId: notice.topicId,
            commentId: notice.commentId,
            noticeId: notice.id
          })
        )
      )
        .then(() => dispatch(closeNoticesAction(notices)))
        .catch(handleError),
    [closeNotice, handleError]
  )

  const onRejectComment = useCallback(
    (comment: Comment, reason: RejectReason) =>
      rejectComment({
        topicId: comment.topicId,
        commentId: comment.id,
        reason
      })
        .then(rejectedComment => dispatch(moderateCommentAction(rejectedComment, 'REJECT')))
        .catch(handleError),
    [rejectComment, handleError]
  )

  const onAcceptComment = useCallback(
    (comment: Comment) =>
      acceptComment({
        topicId: comment.topicId,
        commentId: comment.id
      })
        .then(acceptedComment => dispatch(moderateCommentAction(acceptedComment, 'ACCEPT')))
        .catch(handleError),
    [acceptComment, handleError]
  )

  const onReplyComment = useCallback(
    (comment: api.PostReplyParams) =>
      postComment(comment)
        .then(newComment => dispatch(newCommentAction(newComment)))
        .catch(handleError),
    [postComment, handleError]
  )

  const onUnmoderateComment = useCallback(
    (comment: Comment) => {
      unmoderateComment({ topicId: comment.topicId, commentId: comment.id })
        .then(() => dispatch(updateCommentAction(comment)))
        .catch(handleError)
    },
    [unmoderateComment, handleError]
  )

  const actions: CommentActions = useMemo(
    () => ({
      onCloseNotices,
      onRejectComment,
      onAcceptComment,
      onReplyComment,
      onSetIsAutomoderated,
      onChangeRejectReason,
      onUnmoderateComment
    }),
    [
      onCloseNotices,
      onRejectComment,
      onAcceptComment,
      onReplyComment,
      onSetIsAutomoderated,
      onChangeRejectReason,
      onUnmoderateComment
    ]
  )

  const shownTopics =
    state.topicFilter === 'Upcoming' ? state.topics.filter(isUpcomingTopic) : state.topics

  return {
    ...state,
    topics:
      state.singleTopic !== undefined &&
      shownTopics.find(t => t.id === state.singleTopic?.id) === undefined
        ? [state.singleTopic, ...shownTopics]
        : shownTopics,
    setSelectedTopic,
    loadSingleTopic,
    setTopicFilter,
    setTab,
    actions
  }
}
