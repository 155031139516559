import React, { FC, ReactNode } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Notice } from '../../models/notice'
import { DSA_CATEGORIES } from '../../utils/util'
import { useSettings } from '../../providers/SettingsProvider'

type FlaggingBannerProps = {
  notices: Notice[]
}

const BannerText = ({ notice, hasBorder }: { notice: Notice; hasBorder?: boolean }) => {
  const { language } = useSettings()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          borderTop: hasBorder ? '1px solid #fff' : '',
          paddingTop: '8px'
        }}
      >
        {notice.dsaCategory && (
          <>
            <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '14px' }}>
              REASON:
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '14px', ml: '4px' }}>
              {DSA_CATEGORIES(language)[notice.dsaCategory]}
            </Typography>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          paddingBottom: '8px'
        }}
      >
        {notice.content && (
          <>
            <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '14px' }}>
              DETAILS:
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '14px', ml: '4px' }}>
              {notice.content}
            </Typography>
          </>
        )}
      </Box>
    </>
  )
}

const TextButton = ({ children, onClick }: { children: ReactNode; onClick: () => void }) => (
  <Button
    onClick={onClick}
    variant="text"
    size="small"
    sx={{
      fontSize: '12px',
      color: 'info.contrastText',
      alignSelf: 'flex-start',
      textTransform: 'none',
      padding: '0 0 8px 0',
      minWidth: 0
    }}
  >
    {children}
  </Button>
)

export const FlaggingBanner: FC<FlaggingBannerProps> = ({ notices }) => {
  const [showAll, setShowAll] = React.useState(false)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'info.main',
        color: 'info.contrastText',
        borderRadius: '4px',
        padding: '0 16px',
        margin: '8px 0',
        position: 'relative'
      }}
    >
      <BannerText notice={notices[0]} />
      {notices.length > 1 && (
        <>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              fontSize: '12px',
              position: 'absolute',
              top: '8px',
              right: '8px'
            }}
          >
            Flagged multiple times
          </Typography>
          {showAll ? (
            <>
              {notices.slice(1).map(notice => (
                <BannerText hasBorder key={notice.id} notice={notice} />
              ))}
              <TextButton onClick={() => setShowAll(false)}>Hide</TextButton>
            </>
          ) : (
            <TextButton onClick={() => setShowAll(true)}>Show all ({notices.length})</TextButton>
          )}
        </>
      )}
    </Box>
  )
}
