/* istanbul ignore file */

import React, { FC, ReactNode } from 'react'
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Close } from '@mui/icons-material'

export const SnackbarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const notistackRef = React.createRef<NotistackSnackbarProvider>()
  const onButtonClick = (key: string | number) => () => {
    notistackRef.current?.closeSnackbar(key)
  }
  const theme = useTheme()
  const textColor = theme.palette.primary.contrastText

  return (
    <NotistackSnackbarProvider
      ref={notistackRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      action={key => (
        <IconButton onClick={onButtonClick(key)}>
          <Close fontSize="small" style={{ color: textColor }} />
        </IconButton>
      )}
      dense
    >
      {children}
    </NotistackSnackbarProvider>
  )
}
