import React, { FC, ReactNode } from 'react'
import { Box, Button, Modal as MuiModal, Typography } from '@mui/material'

export type ModalProps = {
  open: boolean
  handleClose: () => void
  title: ReactNode
  children: ReactNode
  actions?: ReactNode
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 620,
  maxWidth: '100%',
  bgcolor: 'background.paper',
  p: '24px 0 0 0',
  borderRadius: 2,
  maxHeight: 'calc(100vh - 48px)',
  overflowY: 'auto'
}

export const Modal: FC<ModalProps> = ({ open, handleClose, actions, title, children }) => {
  if (!open) {
    return null
  }

  return (
    <MuiModal open={open} onClose={handleClose} aria-labelledby="block-modal-title">
      <Box sx={style}>
        <Box sx={{ padding: '0 24px' }}>
          <Typography
            id="block-modal-title"
            variant="h4"
            component="h1"
            sx={{ fontSize: '28px', lineHeight: '36px' }}
          >
            {title}
          </Typography>
          {children}
        </Box>
        <Box
          sx={{
            mt: 2,
            padding: '24px',
            background: '#fff',
            position: 'sticky',
            bottom: '0',
            borderTop: '1px solid #EEEDEE'
          }}
          display="flex"
          justifyContent="end"
        >
          <Button variant="outlined" size="small" onClick={handleClose}>
            Cancel
          </Button>
          {actions}
        </Box>
      </Box>
    </MuiModal>
  )
}
