import React, { FC, useCallback, useRef, useState } from 'react'
import { Box, Button, Chip, Menu, MenuItem } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useKeyboardShortcut } from '../../hooks/keyboardShortcut'
import { RejectReason } from '../../models/rejectReason'
import { REJECT_REASONS } from '../../utils/util'
import { useSettings } from '../../providers/SettingsProvider'

export type RejectButtonProps = {
  onReject: (reason: RejectReason) => void
  text: string
  useKeyboardShortcuts?: boolean
  suggestion?: RejectReason
}

export const RejectButton: FC<RejectButtonProps> = ({
  useKeyboardShortcuts,
  onReject,
  text,
  suggestion = 'TERMS_OR_DISCUSSION_RULES_VIOLATION'
}) => {
  const buttonRef = useRef(null)
  const [open, setOpen] = useState(false)
  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])
  const onClose = () => {
    setOpen(false)
  }
  useKeyboardShortcut(useKeyboardShortcuts || false, 'd', onOpen)
  const { language } = useSettings()

  const suggestedItem = Object.entries(REJECT_REASONS(language)).filter(
    ([reason]) => reason === suggestion
  )[0]

  return (
    <Box sx={{ marginLeft: 1 }}>
      <Button
        onClick={onOpen}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        ref={buttonRef}
        sx={{ color: 'error.main' }}
        variant="outlined"
        size="small"
        endIcon={<KeyboardArrowDown />}
      >
        {`${text}${useKeyboardShortcuts ? ' (D)' : ''}`}
      </Button>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorEl={buttonRef.current}
        open={open}
        onClose={onClose}
      >
        <MenuItem dense onClick={() => onReject(suggestedItem[0] as RejectReason)}>
          {suggestedItem[1]}
          <Chip sx={{ marginLeft: 1 }} size="small" label="Suggested" variant="outlined" />
        </MenuItem>
        {Object.entries(REJECT_REASONS(language))
          .filter(([reason]) => reason !== suggestedItem[0])
          .map(([reason, translation]) => (
            <MenuItem
              disabled={(reason as RejectReason) === 'TOP_COMMENT_REJECTED'}
              dense
              key={reason}
              onClick={() => onReject(reason as RejectReason)}
            >
              {translation}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  )
}
