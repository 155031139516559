import React, { useCallback } from 'react'
import { CommentActions } from '../../hooks/topicsAndComments/topicsAndComments'
import { FilterTab } from './CommentFilterTabs'
import { RenderComment } from './CommentListItem'
import { Button, ButtonProps } from '@mui/material'
import { RejectButton } from './RejectButton'
import { Reply } from '@mui/icons-material'
import { AcceptButton } from './AcceptButton'
import { isAccepted, isRejected } from '../../utils/comment'
import { RejectReason } from '../../models/rejectReason'

type CommentActionButtonsProps = {
  actions: Omit<CommentActions, 'onReplyComment'>
  tab: FilterTab
  comment: RenderComment
  userIsModerator?: boolean
  handleReply?: (comment: RenderComment) => void
  isFirst?: boolean
}

const actionCommonProps: ButtonProps = { sx: { marginLeft: 1 }, size: 'small' }

export const CommentActionButtons = ({
  actions,
  tab,
  comment,
  userIsModerator,
  handleReply,
  isFirst
}: CommentActionButtonsProps) => {
  const onAccept = useCallback(() => {
    actions.onAcceptComment(comment)
  }, [actions, comment])

  const onReject = useCallback(
    (reason: RejectReason) => {
      actions.onRejectComment(comment, reason)
    },
    [actions, comment]
  )

  const reject = (
    <RejectButton
      useKeyboardShortcuts={isFirst && tab === 'UNMODERATED'}
      text={tab === 'FLAGGED' ? 'Reject message' : 'Reject'}
      onReject={onReject}
      suggestion={comment.aiModerationRejectReason || undefined}
    />
  )
  const reply = handleReply ? (
    <Button
      {...actionCommonProps}
      onClick={() => handleReply(comment)}
      variant="outlined"
      startIcon={<Reply />}
    >
      Reply
    </Button>
  ) : null
  const accept = (
    <AcceptButton
      useKeyboardShortcuts={isFirst && tab === 'UNMODERATED'}
      variant={tab === 'REJECTED' ? 'outlined' : 'contained'}
      onAccept={onAccept}
    />
  )
  const closeNotices = (
    <Button
      {...actionCommonProps}
      onClick={() => comment.notices && actions.onCloseNotices(comment.notices)}
      variant="contained"
    >
      Ignore flagging
    </Button>
  )

  switch (tab) {
    case 'ALL':
      return (
        <>
          {userIsModerator && !isAccepted(comment) ? accept : null}
          {userIsModerator && !isRejected(comment) ? reject : null}
          {isAccepted(comment) ? reply : null}
        </>
      )
    case 'ACCEPTED':
      return (
        <>
          {userIsModerator ? reject : null}
          {reply}
        </>
      )
    case 'UNMODERATED':
      return (
        <>
          {reject}
          {accept}
        </>
      )
    case 'FLAGGED':
      return (
        <>
          {reject}
          {closeNotices}
        </>
      )
    case 'REJECTED':
      return <>{accept}</>
  }
}
