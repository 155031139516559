import React, { FC } from 'react'
import { RenderComment } from '../Comment/CommentListItem'
import {
  AiModeratorLoveEyesIcon,
  AiModeratorCircleEyesIcon,
  AiModeratorCrossEyesIcon
} from './AiModeratorIcon'
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { REJECT_REASONS } from '../../utils/util'
import { useSettings } from '../../providers/SettingsProvider'

type AiModerationProps = {
  comment: RenderComment
  hideRejectReason?: boolean
}

const fontSettings = { fontSize: '11px', lineHeight: '16px' }

const AiColorIndicator: FC<{ color: string | null }> = ({ color }) => (
  <Box
    sx={{
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: color
    }}
  />
)

const AiModerationIcon = ({ label }: { label: number | null }) => {
  switch (label) {
    case 1:
      return <AiModeratorCrossEyesIcon />
    case 0:
      return <AiModeratorLoveEyesIcon />
    default:
      return <AiModeratorCircleEyesIcon />
  }
}

const AiModerationDetails = ({ label }: { label: number }) => {
  let text, color
  if (label > 0 && label < 1) {
    // Deprecated version of the AI label used a float between 0 and 1.
    // After DSA modifications (end of 2024), the label is binary (0 or 1).
    // This if-branch was kept for backwards compatibility during transition phase to DSA.
    text = `${Math.round((1 - label) * 100)} % eligible`
    color = 'warning.main'
  } else if (label === 0) {
    text = 'eligible'
  } else {
    text = 'ineligible'
    color = 'error.main'
  }

  return (
    <>
      {color && <AiColorIndicator color={color} />}
      <Typography sx={fontSettings}>{text}</Typography>
    </>
  )
}

export const AiModeration: FC<AiModerationProps> = ({ comment, hideRejectReason }) => {
  const { language } = useSettings()
  if (comment.aiModerationStatus === null) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px'
        }}
      >
        <AiModerationIcon label={comment.aiModerationLabel} />
        <Typography sx={{ fontWeight: 500, ...fontSettings }}>AI moderator:</Typography>
        {comment.aiModerationLabel !== null && (
          <AiModerationDetails label={comment.aiModerationLabel} />
        )}
        {(comment.aiModerationStatus === 'pending' ||
          comment.aiModerationStatus === 'in_progress') && (
          <>
            <CircularProgress size={10} sx={{ color: 'primary.main' }} />
            <Typography sx={fontSettings}>loading...</Typography>
          </>
        )}
        {comment.aiModerationStatus === 'error' && (
          <>
            <Typography sx={fontSettings}>failed to moderate this message</Typography>
          </>
        )}
        <Tooltip
          title="AI moderation, powered by large language models (LLMs), 
          assesses messages and categorizes them as either accepted or rejected.
          By analyzing language patterns, it identifies content that may contain 
          offensive language, spam, or harmful material, aiding in maintaining 
          a safe and respectful discussion forum."
          placement="bottom"
        >
          <InfoOutlined sx={{ cursor: 'pointer' }} fontSize="small" />
        </Tooltip>
      </Box>
      {!hideRejectReason && comment.aiModerationRejectReason && (
        <Typography sx={{ ...fontSettings, mt: '2px' }}>{`Reason: ${
          REJECT_REASONS(language)[comment.aiModerationRejectReason]
        }`}</Typography>
      )}
    </Box>
  )
}
