import { DsaCategory } from './dsaCategory'
import { Tag } from './tag'

export type User = {
  id: string
  isBanned: boolean
  nick: string
}

export type Block = {
  blockedUserId: string
  description?: string
  dsaCategory?: DsaCategory
  blockingUserId: string
  blockingUserNick: string
  createdAt: Date
}

export type UserBlock = {
  user: User
  block: Block
}

export type UserCommentStats = {
  accepted: number
  rejected: number
  unmoderated: number
}

export const adminUserRoles = ['isCommentator', 'isModerator', 'isOperator'] as const

export type RoleName = (typeof adminUserRoles)[number]

export type AdminUserRoles = Record<RoleName, boolean>

export type AdminUser = {
  externalId: string
  externalIdSource: 'google'
  id: string
  isActive: boolean
  nick: string
  photoUrl?: string | null
  tags: Tag[]
} & AdminUserRoles
