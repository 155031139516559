import React, { FC, ReactNode } from 'react'
import { createContext } from '../utils/context'

type Settings = {
  language: 'fi' | 'sv'
  onUpdateLanguage: (language: 'fi' | 'sv') => void
}

const [useSettings, Provider] = createContext<Settings>('SettingsContext')
export { useSettings }

const setLocalStorageItem = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value)
  } catch (e) {
    console.error(`Failed to save ${key} to local storage`, e)
  }
}

const getLocalStorageItem = <T extends string>(
  key: string,
  allowedValues: T[],
  defaultValue: T
): T => {
  try {
    const value = localStorage.getItem(key)
    if (value === null) {
      return defaultValue
    }
    return allowedValues.includes(value as T) ? (value as T) : defaultValue
  } catch (e) {
    console.error(`Failed to get ${key} from local storage`, e)
    return defaultValue
  }
}

export const SettingsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = React.useState<'fi' | 'sv'>(
    getLocalStorageItem<'fi' | 'sv'>('commentsAdminLanguage', ['fi', 'sv'], 'fi')
  )
  const onUpdateLanguage = React.useCallback((language: 'fi' | 'sv') => {
    setLanguage(language)
    setLocalStorageItem('commentsAdminLanguage', language)
  }, [])

  return <Provider value={{ language, onUpdateLanguage }}>{children}</Provider>
}
