import { DsaCategory } from '../models/dsaCategory'
import { RejectReason } from '../models/rejectReason'
import { Tag } from '../models/tag'

export const sum = (nums: number[]): number => nums.reduce((x, y) => x + y, 0)

export const notNull = <T>(t: T | null | undefined): t is T => t != null

export const groupBy = <T, K extends string | number | symbol | null | undefined>(
  xs: T[],
  f: (x: T) => K
): Record<NonNullable<K>, T[]> => {
  const ret = {} as Record<NonNullable<K>, T[]>
  for (const x of xs) {
    const k = f(x)
    if (k != null) {
      const arr = ret[k] ?? []
      arr.push(x)
      ret[k] = arr
    }
  }
  return ret
}

export const isAreenaTag = (tag: Tag) => tag.externalId === 'areena' || tag.externalId === 'arenan'

export const difference = <T>(setA: Set<T>, setB: Set<T>): Set<T> => {
  const diff = new Set(setA)
  for (const elem of setB) {
    diff.delete(elem)
  }
  return diff
}

export const REJECT_REASONS_FI: Record<RejectReason, string> = {
  TERMS_OR_DISCUSSION_RULES_VIOLATION: 'Keskusteluohjeen vastainen sisältö',
  NEGATIVE_EFFECTS_ON_CIVIC_DISCOURSE_OR_ELECTIONS:
    'Kielteiset vaikutukset kansalaiskeskusteluun tai vaaleihin',
  ILLEGAL_OR_HARMFUL_SPEECH: 'Laiton tai haitallinen puhe',
  VIOLENCE: 'Väkivalta',
  CYBER_VIOLENCE: 'Verkkoväkivalta',
  CYBER_VIOLENCE_AGAINST_WOMEN: 'Naisiin kohdistuva verkkoväkivalta',
  CONSUMER_INFORMATION: 'Kuluttajatietoja koskevat rikkomukset',
  SELF_HARM: 'Itsensä vahingoittaminen',
  ANIMAL_WELFARE: 'Eläinten hyvinvointi',
  DATA_PROTECTION_AND_PRIVACY_VIOLATIONS: 'Tietosuojan ja yksityisyyden loukkaukset',
  INTELLECTUAL_PROPERTY_INFRINGEMENTS: 'Teollis- ja tekijänoikeuksien loukkaukset',
  PROTECTION_OF_MINORS: 'Alaikäisten suojelu',
  RISK_FOR_PUBLIC_SECURITY: 'Yleiseen turvallisuuteen kohdistuva riski',
  SCAMS_AND_FRAUD: 'Huijaukset ja/tai petokset',
  UNSAFE_AND_PROHIBITED_PRODUCTS: 'Vaaralliset, vaatimustenvastaiset tai kielletyt tuotteet',
  TOP_COMMENT_REJECTED: 'Ketjun aloitusviesti hylätty'
}

export const REJECT_REASONS_SV: Record<RejectReason, string> = {
  TERMS_OR_DISCUSSION_RULES_VIOLATION: 'Innehåll som strider mot diskussionspolicyn',
  NEGATIVE_EFFECTS_ON_CIVIC_DISCOURSE_OR_ELECTIONS:
    'Negativa effekter på det offentliga samtalet eller på val',
  ILLEGAL_OR_HARMFUL_SPEECH: 'Olagliga eller skadliga yttranden',
  VIOLENCE: 'Våld',
  CYBER_VIOLENCE: 'Cybervåld',
  CYBER_VIOLENCE_AGAINST_WOMEN: 'Cybervåld mot kvinnor',
  CONSUMER_INFORMATION: 'Överträdelse avseende konsumentinformation',
  SELF_HARM: 'Självskadebeteenden',
  ANIMAL_WELFARE: 'Djurskydd',
  DATA_PROTECTION_AND_PRIVACY_VIOLATIONS: 'Överträdelse av dataskydd och rätten till privatliv',
  INTELLECTUAL_PROPERTY_INFRINGEMENTS: 'Immaterialrättsintrång',
  PROTECTION_OF_MINORS: 'Skydd av minderåriga',
  RISK_FOR_PUBLIC_SECURITY: 'Risk för allmän säkerhet',
  SCAMS_AND_FRAUD: 'Bedrägeri',
  UNSAFE_AND_PROHIBITED_PRODUCTS: 'Farliga eller förbjudna produkter och ämnen',
  TOP_COMMENT_REJECTED: 'Toppkommentar avvisad'
}

export const REJECT_REASONS = (language: 'fi' | 'sv'): Record<RejectReason, string> =>
  language === 'fi' ? REJECT_REASONS_FI : REJECT_REASONS_SV

export const DSA_CATEGORIES = (language: 'fi' | 'sv'): Record<DsaCategory, string> => {
  const REASON = language === 'fi' ? REJECT_REASONS_FI : REJECT_REASONS_SV
  return {
    STATEMENT_CATEGORY_ANIMAL_WELFARE: REASON.ANIMAL_WELFARE,
    STATEMENT_CATEGORY_CONSUMER_INFORMATION: REASON.CONSUMER_INFORMATION,
    STATEMENT_CATEGORY_CYBER_VIOLENCE: REASON.CYBER_VIOLENCE,
    STATEMENT_CATEGORY_CYBER_VIOLENCE_AGAINST_WOMEN: REASON.CYBER_VIOLENCE_AGAINST_WOMEN,
    STATEMENT_CATEGORY_DATA_PROTECTION_AND_PRIVACY_VIOLATIONS:
      REASON.DATA_PROTECTION_AND_PRIVACY_VIOLATIONS,
    STATEMENT_CATEGORY_ILLEGAL_OR_HARMFUL_SPEECH: REASON.ILLEGAL_OR_HARMFUL_SPEECH,
    STATEMENT_CATEGORY_INTELLECTUAL_PROPERTY_INFRINGEMENTS:
      REASON.INTELLECTUAL_PROPERTY_INFRINGEMENTS,
    STATEMENT_CATEGORY_NEGATIVE_EFFECTS_ON_CIVIC_DISCOURSE_OR_ELECTIONS:
      REASON.NEGATIVE_EFFECTS_ON_CIVIC_DISCOURSE_OR_ELECTIONS,
    STATEMENT_CATEGORY_PROTECTION_OF_MINORS: REASON.PROTECTION_OF_MINORS,
    STATEMENT_CATEGORY_RISK_FOR_PUBLIC_SECURITY: REASON.RISK_FOR_PUBLIC_SECURITY,
    STATEMENT_CATEGORY_SCAMS_AND_FRAUD: REASON.SCAMS_AND_FRAUD,
    STATEMENT_CATEGORY_SELF_HARM: REASON.SELF_HARM,
    STATEMENT_CATEGORY_UNSAFE_AND_PROHIBITED_PRODUCTS: REASON.UNSAFE_AND_PROHIBITED_PRODUCTS,
    STATEMENT_CATEGORY_VIOLENCE: REASON.VIOLENCE,
    STATEMENT_CATEGORY_OTHER_VIOLATION_TC: REASON.TERMS_OR_DISCUSSION_RULES_VIOLATION
  }
}
