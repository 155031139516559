import React, { FC, useEffect } from 'react'
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { Modal } from '../Modal'
import { useSettings } from '../../providers/SettingsProvider'

type SettingsModalProps = {
  open: boolean
  onClose: () => void
}

export const SettingsModal: FC<SettingsModalProps> = ({ open, onClose }) => {
  const { onUpdateLanguage, language } = useSettings()
  const [selectedLanguage, setSelectedLanguage] = React.useState(language)

  useEffect(() => {
    setSelectedLanguage(language)
  }, [language])

  const handleUpdateLanguage = () => {
    onUpdateLanguage(selectedLanguage)
    onClose()
  }

  return (
    <Modal
      open={open}
      handleClose={onClose}
      title="Settings"
      actions={
        <>
          <Button
            onClick={handleUpdateLanguage}
            sx={{ marginLeft: 1 }}
            variant="contained"
            disabled={selectedLanguage === language}
          >
            Save
          </Button>
        </>
      }
    >
      <FormControl sx={{ marginTop: 2 }} fullWidth>
        <InputLabel id="settings-language">Language</InputLabel>
        <Select
          onChange={e => setSelectedLanguage(e.target.value as 'fi' | 'sv')}
          labelId="settings-language"
          label="Language"
          value={selectedLanguage}
        >
          <MenuItem value="fi">Finnish</MenuItem>
          <MenuItem value="sv">Swedish</MenuItem>
        </Select>
        <FormHelperText>
          Language settings currently only impact DSA rejection reasons.
        </FormHelperText>
      </FormControl>
    </Modal>
  )
}
